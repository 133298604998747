<template>
  <!-- Start Single Slider -->
  <div>
    <div
      class="hero-header slider slider--3 ptb--200 pl--150 rfanimation-style--2"
      v-for="(slider, i) in sliderContent"
      :key="i"
      :class="slider.imgClass"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="content">
              <h3 class="heading-title">{{ slider.subTitle }}</h3>
              <h1>{{ slider.title }}</h1>
              <p class="description">
                {{ slider.desc }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliderContent: [
        {
          imgClass: "bg_image--8",
          title: "Creative \nJourneys",
          subTitle: "",
          desc: `Creative workshops engaging children and bridging communities.`,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.hero-header {
  min-height: 500px;
  padding:300px 40px 5px 40px !important;
  position: relative !important;
  bottom: 0 !important;
}
.hero-header .container {
  position: relative !important;
  bottom: 0 !important;
}

.hero-header .content {
  color: white !important;
}

.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .slider.slider--3 {
    padding: 100px 0;
  }
}
</style>
